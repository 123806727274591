<template>
  <section class="mt-5 box p-4">
    <!-- <div class="w-full bg-white rounded-md mt-2 p-4">
      <div class="w-full flex gap-2 items-center">
        <i class="pi pi-list"></i>
        <p class="text-lg font-bold">{{ route.params.id ? 'Actualizar' : 'Crear' }} Bodega</p>
      </div>
    </div> -->
    <div class="flex gap-2 items-center">
      <i class="pi pi-user"></i>
      <p class="text-lg font-bold">{{ route.params.id ? 'Actualizar Paciente' : 'Crear Paciente' }}</p>
    </div>
    <div class="grid grid-cols-2 gap-10 mb-5">
      <div class="grid grid-cols-2 gap-4 mb-auto relative">
        <Badge v-if="model.U_ACS_Activo && model.U_ACS_Activo == 'N'" :value="model.U_ACS_Activo == 'N' ? 'Inactivo': ''" severity="danger" class="badge-inact"></Badge>
        <div class="w-full mt-4 border-b-2 border-blue-600 col-span-2">
          <p class="text-blue-600 font-medium text-md">
            Información Personal
          </p>
        </div>
        <div class="grid grid-cols-3 gap-2">
          <div class="flex flex-col col-start-1">
            <span class="font-bold my-auto">Tipo ident </span>
            <!-- <InputTexts="rounded w-full border uppercase" :disabled="route.params.id" type="text" v-model="model.ItemCode" /> -->
              <Dropdown v-model="model.U_ACS_TpoIdentf" :options="tiposIdent" placeholder="Seleccione un tipo" class="w-full"/>
                <MessageError :text="errors.U_ACS_TpoIdentf"/>
          </div>
          <div class="flex flex-col col-span-2">
            <span class="font-bold my-auto">No. Documento </span>
              <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_ACS_NmrIdentf" @focusout="validarPaciente"/>
              <MessageError :text="errors.U_ACS_NmrIdentf"/>
          </div>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Primer Nombre</span>
            <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_ACS_PrimerNmb"/>
             <MessageError :text="errors.U_ACS_PrimerNmb"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Segundo Nombre</span>
            <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_ACS_SegundoNmb"/>
          <!-- <MessageError :text="errors.U_ACS_SegundoNmb"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Primer Apellido</span>
            <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_ACS_PrimerAplld"/>
            <MessageError :text="errors.U_ACS_PrimerAplld"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Segundo Apellido</span>
            <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_ACS_SegundoAplld"/>
            <!-- <MessageError :text="errors.U_ACS_SegundoAplld"/> -->
        </div>
        <div class="grid grid-cols-2 gap-2">
          <div class="flex flex-col">
            <span class="font-bold my-auto">Fecha de Nacimiento</span>
              <input class="p-2 border rounded-md w-full" type="date" name="" v-model="model.U_ACS_FchNacim">
              <MessageError :text="errors.U_ACS_FchNacim"/>
          </div>
          <div class="flex flex-col">
            <span class="font-bold my-auto">Sexo </span>
              <Dropdown v-model="model.U_ACS_Sexo" :options="tipoSexo" class="w-full"/>
              <MessageError :text="errors.U_ACS_Sexo"/>
          </div>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Lugar de nacimiento</span>
            <InputText class="rounded w-full border uppercase w-full" type="text" v-model="model.U_ACS_LgrNacmt"/>
            <!-- <MessageError :text="errors.U_ACS_LgrNacmt"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Condición Usuario</span>
            <Dropdown v-model="model.U_ACS_CondUsuario" :options="condUsu" class="w-full"/>
            <!-- <MessageError :text="errors.U_ACS_CondUsuario"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Exonerado</span>
            <Dropdown v-model="model.U_ACS_Exonerado" :options="exonera" optionLabel="label" optionValue="value" class="w-full"/>
            <MessageError :text="errors.U_ACS_Exonerado"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Mot Exonera</span>
            <Dropdown v-model="model.U_ACS_MotivoExon" :options="motExonera" optionLabel="label" optionValue="value" class="w-full"/>
            <!-- <MessageError :text="errors.U_ACS_MotivoExon"/> -->
        </div>
        <div class="w-full mt-4 border-b-2 border-blue-600 col-span-2">
          <p class="text-blue-600 font-medium text-md">
            Información Adicional
          </p>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Estado Civil</span>
            <Dropdown v-model="model.U_ACS_EstCivil" :options="estCivil" class="w-full"/>
            <!-- <MessageError :text="errors.U_ACS_EstCivil"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Grupo Sang.</span>
            <InputText class="rounded w-full border uppercase w-full" type="text" v-model="model.U_ACS_Rh"/>
            <!-- <MessageError :text="errors.U_ACS_Rh"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">No. hijos</span>
            <InputText class="rounded w-full border uppercase w-full" type="number" v-model="model.U_ACS_NmrHijos"/>
            <!-- <MessageError :text="errors.U_ACS_NmrHijos"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Zona</span>
            <Dropdown v-model="model.U_ACS_Zona" :options="zonas" class="w-full"/>
             <MessageError :text="errors.U_ACS_Zona"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Descripción Adicional</span>
            <textarea class="rounded w-full border uppercase w-full" v-model="model.U_ACS_DescAdic"/>
            <!-- <MessageError :text="errors.U_ACS_Zona"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Puntaje Sisben </span>
            <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_ACS_Sisben"/>
            <!-- <MessageError :text="errors.U_ACS_Sisben"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Fallecido</span>
            <Dropdown v-model="model.U_ACS_Fallecido" :options="fallecido" optionLabel="label" optionValue="value" class="w-full"/>
            <!-- <MessageError :text="errors.U_ACS_Zona"/> -->
        </div>
        <InputText hidden class="rounded w-full border uppercase" type="text" value="Paciente"
                   v-model="model.U_ACS_Estado"/>
      </div>
      <div class="grid grid-cols-2 gap-4 mb-auto">
        <div class="w-full mt-4 border-b-2 border-blue-600 col-span-2">
          <p class="text-blue-600 font-medium text-md">
            Información de facturación
          </p>
        </div>
        <div class="grid grid-cols-3 gap-2">
          <div class="flex flex-col">
            <span class="font-bold my-auto">Régimen </span>
              <Dropdown v-model="model.U_ACS_Regimen" :options="tiposRegimen" @change="ModRegimen" class="w-full"/>
              <MessageError :text="errors.U_ACS_Regimen"/>
          </div>
          <div class="flex flex-col">
            <span class="font-bold my-auto">Nivel</span>
              <Dropdown v-model="model.U_ACS_Nivel" :options="niveles" class="w-full"/>
              <MessageError :text="errors.U_ACS_Nivel"/>
          </div>
          <div class="flex flex-col">
            <span class="font-bold my-auto">Tipo Usuario</span>
              <Dropdown v-model="model.U_ACS_TpoUsuario" :options="tipoUsu" class="w-full"/>
              <MessageError :text="errors.U_ACS_TpoUsuario"/>
          </div>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Cliente</span>
            <AutoComplete class="autocomplete-sm w-full" v-model="model.U_ACS_ClienInter" field="CardName" forceSelection dropdown
                          :suggestions="clientes" @complete="searchClientes" @item-select="changeCliente"/>
            <!-- <Dropdown v-model="model.U_ACS_ClienInter" :options="clientes"/> -->
            <MessageError :text="errors.U_ACS_ClienInter"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">EPS Paciente </span>
            <AutoComplete class="autocomplete-sm w-full" v-model="model.U_ACS_NmbEntidad" field="U_PHR_NombreCL" forceSelection dropdown
                          :suggestions="entidades" @complete="searchEntidades" @item-select="changeEntidad"/>
             <MessageError :text="errors.U_ACS_NmbEntidad"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Grupo poblacional</span>
            <Dropdown v-model="model.U_ACS_GrpPoblac" :options="grupoPobla" class="w-full"/>
            <!-- <MessageError :text="errors.U_ACS_Zona"/> -->
        </div>
        <div class="w-full mt-5 mb-6 border-b-2 border-blue-600 col-span-2">
          <p class="text-blue-600 font-medium text-md">
            Zonificación
          </p>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Dpto Zonifica</span>
            <AutoComplete class="autocomplete-sm w-full" v-model="model.U_ACS_Dpto" field="nomDpto" optionValue="nomDpto" forceSelection dropdown
                          :suggestions="dptos" @complete="searchDpto" @item-select="changeDpto"/>
                          <MessageError :text="errors.U_ACS_Dpto"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Ciudad Zonifica</span>
            <AutoComplete class="autocomplete-sm w-full" v-model="model.U_ACS_Ciudad" forceSelection dropdown
                          :suggestions="ciudades" @complete="searchCiudades($event, model.U_ACS_Dpto)"/>
          <MessageError :text="errors.U_ACS_Ciudad"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Barrio Zonidica</span>
            <InputText class="rounded w-full border uppercase w-full" type="text" v-model="model.U_ACS_Barrio"/>
            <!-- <MessageError :text="errors.U_ACS_Barrio"/> -->
        </div>
        <div class="w-full mt-4 border-b-2 border-blue-600 col-span-2">
          <p class="text-blue-600 font-medium text-md">
            Información de Contacto
          </p>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Teléfono </span>
            <InputText class="rounded w-full border uppercase w-full" type="text" v-model="model.U_ACS_Telef"/>
             <MessageError :text="errors.U_ACS_Telef"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Movil </span>
            <InputText class="rounded w-full border uppercase w-full" type="text" v-model="model.U_ACS_Movil"/>
             <MessageError :text="errors.U_ACS_Movil"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Email </span>
            <InputText class="rounded w-full border uppercase w-full" type="text" v-model="model.U_ACS_EMail"/>
            <!-- <MessageError :text="errors.U_ACS_Barrio"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Dirección Domi</span>
            <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_ACS_Domicilio"/>
            <!-- <MessageError :text="errors.U_ACS_Domicilio"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Barrio Domi</span>
            <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_barrio_domicilio"/>
            <!-- <MessageError :text="errors.U_ACS_Barrio"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Dpto Domi</span>
            <AutoComplete class="autocomplete-sm w-full" v-model="model.U_ACS_Dpto_domicilio" field="nomDpto" optionValue="label" forceSelection dropdown
                          :suggestions="dptos" @complete="searchDpto" @item-select="changeDptoDomi"/>
                          <MessageError :text="errors.U_ACS_Dpto_domicilio"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold my-auto">Ciudad Domi</span>
            <AutoComplete class="autocomplete-sm w-full" v-model="model.U_ACS_Ciudad_domicilio" forceSelection
                          dropdown :suggestions="ciudades"
                          @complete="searchCiudades($event, model.U_ACS_Dpto_domicilio)"/>
            <MessageError :text="errors.U_ACS_Ciudad_domicilio"/>
        </div>
      </div>
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Cancelar"
              class="bg-blue-900 p-button-outlined"
              @click="$router.back()"
      />
      <Button label="Guardar"
              class="bg-blue-900 mx-5 px-5"
              type="button"
              @click="onSubmit"
      />
    </div>
    <Dialog headerClass="text-xs" header="Pacientes" :closable="false" v-model:visible="modalValidPaciente" :style="{width: '30vw'}" :modal="true">
      <div class="flex gap-2 justify-between mt-2 text-xs">
        <div>
          <p class="my-2">
            Ya se encuentra registrado este documento
          </p>
          <p v-for="(pac, idx) in pacienteSearch" :key="idx" class="font-bold">
            {{ pac.U_ACS_TpoIdentf }} - {{ pac.U_ACS_NmrIdentf }} - {{ pac.U_ACS_NmbPct }}
            <button class="ml-3" v-tooltip="'Actualizar'" @click="EditarPaciente(pac.Id)"><i class="pi pi-replay"></i></button>
          </p>
        </div>
      </div>
      <template #footer>
        <!-- <Button class="p-button" @click="modalValidPaciente = false" label="Actualizar" /> -->
        <Button class="p-button-outlined p-button-secondary" @click="modalValidPaciente = false" label="Continuar" />
      </template>
    </Dialog>
    <Dialog headerClass="text-xs" header="Ya se encuentra registrado este documento" :closable="false" v-model:visible="modalValidUpdate" :style="{width: '30vw'}" :modal="true">
      <div class="flex gap-2 justify-between mt-2 text-xs">
        <div>
          <p class="font-bold">
            {{ pacienteValid.U_ACS_TpoIdentf }} - {{ pacienteValid.U_ACS_NmrIdentf }} - {{ pacienteValid.U_ACS_NmbPct }}
          </p>
        </div>
      </div>
      <template #footer>
        <Button class="p-button" @click="EditarPaciente(pacienteValid.Id)" label="Actualizar" />
        <Button class="p-button-outlined p-button-secondary" @click="modalValidUpdate = false, model.U_ACS_TpoIdentf = '', model.U_ACS_NmrIdentf = ''" label="Limpiar" />
      </template>
    </Dialog>
  </section>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useField, useForm } from 'vee-validate'
import { useRoute, useRouter } from 'vue-router'
// import { useToast } from 'primevue/usetoast'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import PacienteService from '../../../../../services/pacientes.service'
import TasaService from '../../../../../services/calculo-cuota.service'

// instancias
const router = useRouter()
const route = useRoute()
// const toast = useToast()
const _PacienteService = ref(new PacienteService())
const _TasaService = ref(new TasaService())
const dptos = ref([])
const ciudades = ref([])
const tiposIdent = ref(['CC', 'CE', 'PA', 'TI', 'NV', 'MS', 'AS', 'PE', 'PT', 'RC', 'SC'])
const tipoSexo = ref(['Masculino', 'Femenino'])
const tipoUsu = ref(['Cotizante', 'Beneficiario'])
const niveles = ref(['N1', 'N2', 'N3', 'R1', 'R2', 'R3'])
const exonera = ref([{ label: 'Si', value: 'S' }, { label: 'No', value: 'N' }])
const motExonera = ref([{ value: '01', label: 'Fallo Judicial' }, { value: '02', label: 'Patologia' }, {
  value: '03',
  label: 'Población Especial'
}])
const condUsu = ref(['Sin Discapacidad', 'Discapacitado'])
const fallecido = ref([{ label: 'Si', value: 'S' }, { label: 'No', value: 'N' }])
const tiposRegimen = ref(['Subsidiado', 'Contributivo'])
const estCivil = ref(['Soltero', 'Casado', 'Divorciado', 'Viudo', 'Concubinato'])
const zonas = ref(['Urbana', 'Rural'])
const grupoPobla = ref(['Desplazado', 'Poblacion SISBEN'])
const clientes = ref([])
const entidades = ref([])
const modalValidPaciente = ref(false)
const modalValidUpdate = ref(false)
const pacienteSearch = ref(null)
const pacienteValid = ref(null)
// const entidadSelect = ref(null)
// const clienteSelect = ref(null)
// referencias

const validationSchema = yup.object({
  U_ACS_TpoIdentf: yup.string().nullable().required('Requerido').label(''),
  U_ACS_NmrIdentf: yup.string().nullable().required('Requerido').label(''),
  U_ACS_PrimerNmb: yup.string().nullable().required('Requerido').label(''),
  U_ACS_PrimerAplld: yup.string().nullable().required('Requerido').label(''),
  U_ACS_FchNacim: yup.string().nullable().required('Requerido').label(''),
  U_ACS_Sexo: yup.string().nullable().required('Requerido').label(''),
  U_ACS_NmbEntidad: yup.string().nullable().required('Requerido').label(''),
  U_ACS_ClienInter: yup.string().nullable().required('Requerido').label(''),
  U_ACS_Regimen: yup.string().nullable().required('Requerido').label(''),
  U_ACS_Nivel: yup.string().nullable().required('Requerido').label(''),
  U_ACS_TpoUsuario: yup.string().nullable().required('Requerido').label(''),
  U_ACS_Exonerado: yup.string().nullable().required('Requerido').label(''),
  U_ACS_Dpto: yup.string().nullable().required('Requerido').label(''),
  U_ACS_Ciudad: yup.string().nullable().required('Requerido').label(''),
  U_ACS_Telef: yup.string().nullable().max(10, 'Máximo 10 caracteres').required('Requerido').label(''),
  U_ACS_Movil: yup.string().nullable().max(10, 'Máximo 10 caracteres').required('Requerido').label(''),
  U_ACS_Dpto_domicilio: yup.string().nullable().required('Requerido').label(''),
  U_ACS_Ciudad_domicilio: yup.string().nullable().required('Requerido').label(''),
  U_ACS_Zona: yup.string().nullable().required('Requerido').label('')
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

useField('U_ACS_TpoIdentf', null, { initialValue: '' })
useField('U_ACS_NmrIdentf', null, { initialValue: '' })
useField('U_ACS_PrimerNmb', null, { initialValue: '' })
useField('U_ACS_PrimerAplld', null, { initialValue: '' })
useField('U_ACS_FchNacim', null, { initialValue: '' })
useField('U_ACS_Sexo', null, { initialValue: '' })
useField('U_ACS_NmbEntidad', null, { initialValue: '' })
useField('U_ACS_ClienInter', null, { initialValue: '' })
useField('U_ACS_Regimen', null, { initialValue: '' })
useField('U_ACS_Nivel', null, { initialValue: '' })
useField('U_ACS_TpoUsuario', null, { initialValue: '' })
useField('U_ACS_Exonerado', null, { initialValue: '' })
useField('U_ACS_Dpto', null, { initialValue: '' })
useField('U_ACS_Ciudad', null, { initialValue: '' })
useField('U_ACS_Telef', null, { initialValue: '' })
useField('U_ACS_Movil', null, { initialValue: '' })
useField('U_ACS_Dpto_domicilio', null, { initialValue: '' })
useField('U_ACS_Zona', null, { initialValue: '' })
useField('U_ACS_Ciudad_domicilio', null, { initialValue: '' })

const getPaciente = (id) => {
  _PacienteService.value.getPac(id).then(async ({ data }) => {
    for (const [index, item] of Object.entries(data)) {
      // console.log('-lo que va insertando-', item)
      model[index] = item
    }
    if (!route.params.id) {
      router.push({ name: 'pharmasan.ventas.configuracion.pacientes.editar', params: { id } })
    }
  })
}
const onSubmit = handleSubmit(async (values) => {
  if (!values) throw new Error('Error de validación')
  // console.log(model)
  model.U_ACS_PrimerNmb = model.U_ACS_PrimerNmb ? model.U_ACS_PrimerNmb.toUpperCase() : ''
  model.U_ACS_SegundoNmb = model.U_ACS_SegundoNmb ? model.U_ACS_SegundoNmb.toUpperCase() : ''
  model.U_ACS_PrimerAplld = model.U_ACS_PrimerAplld ? model.U_ACS_PrimerAplld.toUpperCase() : ''
  model.U_ACS_SegundoAplld = model.U_ACS_SegundoAplld ? model.U_ACS_SegundoAplld.toUpperCase() : ''
  if (route.params.id) {
    _PacienteService.value.editarPac(model, route.params.id).then((res) => {
      //     // toast.add({ severity: 'success', summary: 'Exito', detail: res, life: 3000 })
      Swal.fire({
        icon: 'success',
        title: 'Paciente Actualizado',
        text: 'El Paciente se ha actualizado correctamente'
      }).then(() => {
        handleReset()
        router.push({ name: 'pharmasan.ventas.configuracion.pacientes' })
      })
    })
  } else {
    _PacienteService.value.crearPac(model).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Paciente Creado',
        text: 'El Paciente se ha creado correctamente'
      }).then(() => {
        handleReset()
        router.push({ name: 'pharmasan.ventas.configuracion.pacientes' })
      })
    })
  }
})

const searchDpto = (event) => {
  if (event) {
    _PacienteService.value.getDptos(event.query).then(({ data }) => {
      // dptos.value = data.map(m => m.nomMpio)
      // tomar el mismo listado de ciudades mapea por depto y quita repetidos
      // dptos.value = [...new Set(data.map(m => m.nomDpto))]
      // console.log('listando dptos', data)
      dptos.value = data
    })
  }
}

const searchCiudades = (event, dato) => {
  if (event) {
    _PacienteService.value.getCiudades(event.query).then(({ data }) => {
      var cities = []
      if (dato) {
        cities = data.filter(m => m.nomDpto === dato)
      }
      // console.log('lo que llega de listar city--', dato, '--dato--', data)
      ciudades.value = cities.map(m => m.nomMpio)
    })
  }
}
const searchEntidades = (event) => {
  if (event) {
    _PacienteService.value.getEntidades(event.query).then(({ data }) => {
      entidades.value = data
    })
  }
}
const searchClientes = (event) => {
  if (event) {
    _PacienteService.value.getClientes(event.query).then(({ data }) => {
      clientes.value = data
    })
  }
}

const changeEntidad = (event) => {
  // console.log('changeEntidad', event)
  if (event.value) {
    model.U_ACS_NmbEntidad = event.value.U_PHR_NombreCL
    model.U_ACS_Entidad = event.value.U_PHR_ClientePHR
  }
}
const changeCliente = (event) => {
  if (event.value) {
    model.U_ACS_ClienInter = event.value.CardName
  }
}

const changeDpto = (event) => {
  if (event.value) {
    model.U_ACS_Dpto = event.value.nomDpto
  }
}

const changeDptoDomi = (event) => {
  if (event.value) {
    model.U_ACS_Dpto_domicilio = event.value.nomDpto
  }
}

const ModRegimen = () => {
  model.U_ACS_Nivel = ''
  _TasaService.value.paginate().then(({ data }) => {
    // console.log('mapeando--', data.rows)
    niveles.value = data.rows.filter(n => {
    if (model.U_ACS_Regimen === 'Contributivo') {
      // console.log('Contributivo--', n)
      if (n.U_PHR_Regimen === 2) {
        return n.Code
      }
    } else if (model.U_ACS_Regimen === 'Subsidiado') {
      // console.log('Subsidiado--', n)
      if (n.U_PHR_Regimen === 1) {
        return n.Code
      }
    }
  }).map(m => m.Code)
})
}

const EditarPaciente = (idPac = null) => {
  modalValidUpdate.value = false
  modalValidPaciente.value = false
  let IdPaciente = null
  if (idPac) {
    IdPaciente = idPac
  } else {
    IdPaciente = pacienteValid.value ? pacienteValid.value.Id : 0
  }
  getPaciente(IdPaciente)
}

const validarPaciente = () => {
  if (model.U_ACS_NmrIdentf) {
    _PacienteService.value.listarPacientes({ U_ACS_NmrIdentf: model.U_ACS_NmrIdentf }).then(({ data }) => {
      if (data.length) {
        pacienteSearch.value = data
        pacienteValid.value = data.find(f => (f.U_ACS_TpoIdentf === model.U_ACS_TpoIdentf && f.U_ACS_NmrIdentf === model.U_ACS_NmrIdentf) || (f.U_ACS_TpoIdentf === 'TI' && model.U_ACS_TpoIdentf === 'CC'))
        if (pacienteValid.value) {
          modalValidUpdate.value = true
        } else {
          modalValidPaciente.value = true
        }
      }
    })
  }
}

onMounted(() => {
  searchClientes({ query: '' })
  searchEntidades({ query: '' })
  if (route.params.id) {
    getPaciente(route.params.id)
  } else {
    handleReset()
  }
  // _PacienteService.value.getDptos().then(({ data }) => {
  //   dptos.value = data
  // })
})
</script>
<style>
.badge-inact{
  position: absolute;
  right: 0;
}
</style>
